<template>
  <!-- eslint-disable -->
  <v-dialog
    :value="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="rounded-0" flat>
      <v-toolbar
        dark
        color="cyan"
      >
        <v-toolbar-title>
          <div class="caption font-weight-bold">
            Acquisition Tools
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="$emit('close', true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div style="max-height: calc(100vh - 64px);overflow-y: auto;">
        <div class="pa-4">
          <v-row no-gutters>
            <v-col cols="6" class="col-md-2 pr-1 pr-sm-0">
              <v-select
                v-model="trxSourceFilter"
                label="Select Source"
                :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
                item-value="id"
                item-text="name"
                block
                outlined
                dense
                hide-details
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent"
                autocomplete="off"
                @change="trxUserAdminFilter = 0"
              />
            </v-col>
            <v-col cols="6" class="col-md-4 pl-1 pl-sm-2 pr-0 pr-sm-2">
              <!-- :disabled="trxSourceFilter !== 'WA'" -->
              <v-select
                v-model.number="trxUserAdminFilter"
                label="Select Admin"
                :items="[{ id: 0, name: 'All' }].concat($store.state.user_admin)"
                :item-text="(v) => {
                  return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
                }"
                item-value="id"
                block
                outlined
                dense
                hide-details
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="6" class="col-md-3 pl-1 pl-sm-2 pr-0 pr-sm-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :return-value.sync="dates"
                left
                offset-y
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateRangeText"
                    label="Date Ranges"
                    append-icon="mdi-calendar-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                    class="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dates = []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  light
                  class="force-light-font"
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="$refs.menu.save(dates)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-9 pa-4">
              <v-card light>
                <apexchart v-if="chartLoadedDAU" ref="chartdau" type="area" :options="chartOptionsDAU" :series="chartSeriesDAU" :height="$store.state.screenWidth < 768 ? 350 : 500" width="100%" />
                <div class="d-flex flex-column fill-width justify-center align-end pt-4">
                  <div class="body-2">
                    Resume :
                  </div>
                  <div class="body-1 font-weight-bold">
                    Leads : {{ TotalLeads }}
                  </div>
                  <div class="body-1 font-weight-bold">
                    Achievement : {{ TotalAchieve }} ({{ (((TotalAchieve || 0) * 100) / (TotalLeads || 0)) }}%)
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-3 pa-4">
              <!-- <v-btn depressed block dark color="teal" class="font-weight-bold caption-small">
                New Target
              </v-btn> -->
              <div class="px-0 py-2" style="height: calc(100vh - 150px);overflow-y:auto;">
                <v-card v-for="(g, iG) in graph" :key="'graph-list-' + iG" class="rounded-0 d-flex fill-width justify-space-between align-center my-1 px-2 py-1 caption">
                  <div class="py-1" style="max-width: calc(100% - 100px);">
                    <small class="d-block grey--text" style="line-height: 140%;">
                      {{ g.selected_date }}
                    </small>
                    <v-chip v-if="!parseInt(g.id)" x-small color="orange" dark class="c-pointer" @click.prevent="TargetOpen(null, g.selected_date)">
                      <v-icon x-small class="">
                        mdi-plus
                      </v-icon>
                    </v-chip>
                    <div v-else class="">
                      <v-chip v-for="(gt, iGt) in (g.targets ? g.targets.split('||') : [])" :key="'gt-' + iG + '-' + iGt" x-small color="indigo" dark class="d-inline-flex mr-1 c-pointer" style="font-size:9px;" @click.prevent="TargetOpen(gt, g.selected_date)">
                        {{ gt }}
                      </v-chip>
                      <v-chip x-small color="orange" dark class="c-pointer" @click.prevent="TargetOpen(null, g.selected_date)">
                        <v-icon x-small class="">
                          mdi-plus
                        </v-icon>
                      </v-chip>
                    </div>
                  </div>
                  <span v-if="parseInt(g.targetted)" class="d-flex">
                    {{ g.result_achieved }}/{{ g.result_target }} ({{ (parseInt(g.result_achieved) && parseInt(g.result_target)) ? ((parseInt(g.result_target)/parseInt(g.result_achieved))/100).toFixed(1) : '0' }}%)
                  </span>
                  <span v-else>
                    -
                  </span>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="dialogTarget.show"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title v-if="dialogTarget.data" class="flex-column align-center justify-center subtitle-1 pb-4">
          {{ dialogTarget.data.id ? 'Update Lead' : 'Lead' }}
          <div class="caption grey--text text--darken-2" style="line-height: 100%;">
            {{ dialogTarget.data.dt }}
          </div>
        </v-card-title>
        <v-card-text v-if="dialogTarget.data" class="pb-0">
          <v-select
            v-model="dialogTarget.data.source"
            label="Select Source"
            :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
            item-value="id"
            item-text="name"
            block
            outlined
            dense
            hide-details
            class="mb-4"
            autocomplete="off"
            @change="dialogTarget.data.uid = 0"
          />
          <!-- :disabled="dialogTarget.data.source !== 'WA'" -->
          <v-select
            v-model.number="dialogTarget.data.uid"
            label="Select Admin"
            :items="[{ id: 0, name: 'All' }].concat($store.state.user_admin)"
            :item-text="(v) => {
              return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
            }"
            item-value="id"
            block
            outlined
            dense
            hide-details
            class="mb-4"
            autocomplete="off"
          />
          <v-text-field
            v-model.number="dialogTarget.data.target"
            label="Target"
            outlined
            dense
            hide-details
            class="mb-4"
            autocomplete="off"
          />
        </v-card-text>
        <v-card-actions v-if="dialogTarget.data">
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize body-2"
            @click="dialogTarget.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn v-if="parseInt(dialogTarget.data.id)" icon small color="red" class="mr-1" @click.prevent="TargetDelete(parseInt(dialogTarget.data.id))">
            <v-icon small>
              mdi-delete-circle
            </v-icon>
          </v-btn>
          <v-btn
            :color="dialogTarget.data.id ? 'green darken-1' : 'indigo darken-1'"
            depressed
            dark
            small
            class="text-capitalize rounded-pill px-4"
            @click="TargetProcess()"
          >
            {{ dialogTarget.data.id ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: (vm) => ({
    dialogTarget: {
      show: false,
      data: null
    },
    trxSourceFilter: '',
    trxUserAdminFilter: 0,
    menu: false,
    dates: [],
    graph: [],
    graphDAU: {
      month: 7
    },
    chartLoadedDAU: false,
    chartSeriesDAU: [],
    chartOptionsDAU: {
      id: 'chart',
      title: {
        enable: false,
        text: 'User Acquisition',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Montserrat, Arial, sans-serif',
          color: '#ffffff'
        }
      },
      chart: {
        stacked: false,
        fontFamily: 'Montserrat, Arial, sans-serif',
        toolbar: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const percent = series[0][dataPointIndex] ? ((series[1][dataPointIndex] / series[0][dataPointIndex]) * 100) : 0
          return '<div class="blue" style="min-width: 120px;">' +
              '<div class="d-flex justify-space-between fill-width py-1 px-2 caption-small font-weight-bold">' + vm.chartOptionsDAU.xaxis.categories[dataPointIndex] + ' <span>' + percent + '%</span></div>' +
              '<div class="px-2 pb-0" style="line-height:140%;">Leads : ' + series[0][dataPointIndex] + '</div>' +
              '<div class="px-2 pb-1" style="line-height:140%;">Achieved : ' + series[1][dataPointIndex] + '</div>' +
            '</div>'
        },
        style: {
          fontSize: '12px'
        },
        y: {
        }
      },
      grid: {
        show: true,
        borderColor: vm.$vuetify.theme.dark ? '#777777' : '#f0f0f0',
        position: 'back',
        row: {
          opacity: 0.1
        },
        column: {
          opacity: 0.1
        },
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 10
        }
      },
      legend: {
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: '#777'
        },
        itemMargin: {
          horizontal: 20,
          vertical: 7
        },
        markers: {
          width: 12,
          height: 12
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        orientation: 'horizontal',
        offsetY: -10,
        background: {
          borderWidth: 0
        },
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          colors: ['#FFA500', '#828282', '#fed766', '#2ab7ca', '#fe4a49']
        }
        // formatter: function (val) {
        //   return (val ? (val + ' (' + (100) + '&)') : (0 + ' (0%)'))
        // }
      },
      fill: {
        colors: ['#FFD8DD']
      },
      stroke: {
        width: 2,
        curve: 'smooth',
        colors: ['#FFA500', '#828282', '#fed766', '#2ab7ca', '#fe4a49']
      },
      annotations: {
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            border: true,
            fontSize: '9px',
            colors: '#777'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#FFFFFF',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        title: {
          style: {
            color: '#FFFFFF'
          }
        }
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            fontSize: '10px',
            colors: '#777'
          }
        }
      }
    },
    TotalLeads: 0,
    TotalAchieve: 0
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    show (v) {
      if (v) {
        this.LoadGraph()
      }
    },
    dates: function (fromTo) {
      if (!fromTo.length || fromTo.length === 2) {
        this.LoadGraph()
      }
    },
    trxSourceFilter () {
      this.LoadGraph()
    },
    trxUserAdminFilter () {
      this.LoadGraph()
    }
  },
  mounted () {
  },
  methods: {
    TargetDelete (tid) {
      if (!confirm('Delete this target ?')) {
        return false
      }
      this.$store.dispatch('ACQUISITION_GRAPH_DELETE', tid)
        .then(() => {
          this.dialogTarget.show = false
          this.dialogTarget.data = null
          this.$store.commit('SNACKBAR', { show: true, text: 'Deleted!' })
          this.LoadGraph()
        })
    },
    TargetOpen (data, dt) {
      const d = data ? data.split('/') : []
      this.dialogTarget.data = Object.assign({}, {
        id: data ? (parseInt(d[3]) || null) : null,
        user: 0,
        source: data ? (d[0] === '#' ? '' : d[0]) : null,
        uid: data ? parseInt(d[1]) : 0,
        target: data ? parseInt(d[2]) : 0,
        dt: dt
      })
      this.dialogTarget.show = true
    },
    TargetProcess () {
      const getMaster = this.$store.state.user_admin.find(r => parseInt(r.id) === parseInt(this.dialogTarget.data.uid))
      this.dialogTarget.user = getMaster ? (parseInt(getMaster.master) || 0) : 0
      this.$store.dispatch('ACQUISITION_GRAPH_PROCESS', this.dialogTarget.data)
        .then((res) => {
          if (res.status) {
            this.dialogTarget.show = false
            this.dialogTarget.data = null
            this.$store.commit('SNACKBAR', { show: true, text: (parseInt(this.dialogTarget.id) ? 'Updated!' : 'Created!') })
            this.LoadGraph()
          } else {
            if (res.data.response.data.message) {
              this.$store.commit('SNACKBAR', { show: true, text: res.data.response.data.message })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
            }
          }
        })
    },
    async LoadGraph () {
      this.chartLoadedDAU = false
      this.chartSeriesDAU = []
      this.chartOptionsDAU.xaxis.categories = []
      this.TotalLeads = 0
      this.TotalAchieve = 0
      const fromTo = this.GenerateFromTo(this.dates)
      const q = ('?x=0' + (fromTo || '')) + (this.trxSourceFilter ? ('&source=' + (this.trxSourceFilter || '')) : '') + (this.trxUserAdminFilter ? ('&uid=' + (this.trxUserAdminFilter || '')) : '')
      await this.$store.dispatch('ACQUISITION_GRAPH_GET', q)
        .then((res) => {
          if (res.status) {
            this.graph = res.data.data || []
            this.chartOptionsDAU.xaxis.categories = this.graph.map(r => {
              return r.selected_date
            })
            this.chartSeriesDAU.push({
              type: 'line',
              name: 'Target',
              data: this.graph.map(r => {
                const d = parseInt(r.result_target) || 0
                this.TotalLeads += d
                return d
              })
            })
            this.chartSeriesDAU.push({
              type: 'line',
              name: 'Achieve',
              data: this.graph.map(r => {
                const d = parseInt(r.result_achieved) || 0
                this.TotalAchieve += d
                return d
              })
            })
          }
        })
      this.chartLoadedDAU = true
    },
    GenerateFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
